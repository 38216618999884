.layoutflow {
  flex-grow: 1;
  position: relative;
  height: 100%;
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  font-size: 12px;
}

.layoutflow .controls button:first-child {
  margin-right: 10px;
}

.layoutflow .react-flow {
  background: white;
}

.layoutflow .react-flow__handle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: lightgray;
}

.layoutflow .react-flow__handle-top {
  top: -16px;
}

.layoutflow .react-flow__handle-bottom {
  bottom: -16px;
}

.layoutflow .react-flow__node {
  width: 130px;
  font-weight: bold;
  border-width: 2px;
}

.layoutflow .prog-node {
  background-color: #F9F871;
}

.layoutflow .finished-node {
  background-color: #00EAC2;
}

.save__controls {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 4;
  font-size: 12px;
}

.save__controls button {
  margin-left: 5px;
}